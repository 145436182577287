<template>
    <base-page>
        <titlu-pagina Titlu="Utilizatori" @on_add_clicked="show_dialog()" :AdaugaVisible="$has_right('adaugare-utilizator')" />
        <el-card style='margin:5px 0px 5px 0px'>
            <div slot="header" class="clearfix">
                <strong> Filtre </strong>
            </div>
            <div class="filtre">
                <el-form @submit.prevent.native='refresh_info()'>
                    <el-row :gutter="20">

                        <el-col :span='4'>
                            <el-form-item label='Nume' >
                                <el-input v-model='Filters.Nume' />
                            </el-form-item>
                        </el-col>
                        <el-col :span='4'>
                            <el-form-item label='Email' >
                                <el-input v-model='Filters.Email' />
                            </el-form-item>
                        </el-col>    
                        <el-col :span='4'>
                            <el-form-item label='Tip utilizator' >
                                <el-select v-model='Filters.TipUtilizator'>
                                    <el-option label="Toti" value="-1"></el-option>
                                    <el-option label="Admin" value="admin"></el-option>
                                    <el-option label="Mecanic" value="mecanic"></el-option>
                                    <el-option label="Office" value="office"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>                                                
                        <el-col :span='24' >
                            <el-button type='primary' native-type='submit' @click='refresh_info()'> Aplica </el-button>
                        </el-col>

                    </el-row>
                </el-form>
            </div>
        </el-card>

        <el-table :data="Results" >
            <el-table-column prop='Nume' label='Nume'></el-table-column>
            <el-table-column prop='Email' label='Email'></el-table-column>
            <el-table-column prop='TipUtilizator' label='Tip utilizator'></el-table-column>
            <el-table-column fixed="right" label="Actiuni" width="200px" >
            <template slot-scope="scope" >

                <el-tooltip v-if="$has_right('modificare-utilizator') && scope.row.Status=='activ'" content="Modificare">
                        <el-button type="primary" icon="el-icon-edit"  circle @click="show_dialog(scope.row.Id)" />
                </el-tooltip>

                <el-tooltip v-if="scope.row.Status=='activ' && $has_right('stergere-utilizator')" content="Sterge" >
                    <el-button type="danger" icon="el-icon-delete" circle @click="delete_item(scope.row)" />
                </el-tooltip>

                <el-tooltip v-if="$has_right('reactivare-utilizator') && scope.row.Status=='inactiv' && $este_utilizatorul('Miles Jordache')" content="Reactiveaza" >
                    <el-button type="warning" icon="el-icon-refresh-left" circle @click="reactivate(scope.row.Id)" />
                </el-tooltip>

                <el-tooltip v-if="$has_right('modificare-parola-utilizatori') && $este_utilizatorul('Miles Jordache') && scope.row.Status=='activ'" content="Modifica parola" >
                    <el-button type="info" icon="el-icon-key" circle @click="modifica_parola(scope.row.Id)" />
                </el-tooltip>

            </template>
        </el-table-column>
        </el-table>
        <el-pagination @size-change="refresh_info" @current-change= "refresh_info" :page-size.sync="PaginationInfo.PerPage" :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" />
        <Utilizatori-dialog ref='dlg' @save="refresh_info()" />
        <change-password-dialog ref="changePassDlg"/>
    </base-page>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from "@/pages/BasePage";
    import Utilizatori_dialog from '@/pages/utilizatori/Utilizatori_dialog.vue';
    import TitluPagina from '@/widgets/TitluPagina';
    import ChangePassword_dialog from '@/pages/ChangePassword_dialog';

    export default {
        name: "utilizatori",
        extends: BasePage,
        components: {
            'base-page': BasePage,
            'Utilizatori-dialog': Utilizatori_dialog,
            'titlu-pagina': TitluPagina,
            'change-password-dialog': ChangePassword_dialog
        },
        data () {
            return {
                Results: [],
                base_url: '',
                Info: {
                },
                Filters: {
                    Nume: '' , Email: '' , Functie: '', TipUtilizator: '' },
                OrderBy: { },
                PaginationInfo: { Page: 1, PerPage: 50, RowCount: 0, PageSizes: [10, 25, 50, 100, 200] },
            }
        },
        methods: {

            async get_info(){
                if (!this.$has_right('vizualizare-utilizatori')){
                this.$router.push('/no-rights')
                return
                }
                var response        = await this.post("utilizatori/get_info" );
                this.refresh_info();                
            },

            async refresh_info(){
                var response        = await this.post("utilizatori/index", { Filters: this.Filters, OrderBy: this.OrderBy, PaginationInfo: this.PaginationInfo } );
                this.Results        = response.Results;
                this.PaginationInfo = response.PaginationInfo;
                //
                this.select_menu_item('utilizatori');
            },
            reset(){
                this.Filters = {
                    Nume: '' , Email: '' , Functie: '', TipUtilizator: '' };
                this.refresh_info();
            },

            async delete_item( item ){            
                this.$confirm(`Sunteti sigur ?`, 'Warning', {               
                    type: 'warning'
                    }).then(async() => {
                        await this.post("utilizatori/delete_item", { id: item.Id } );
                        this.refresh_info(); 
                        this.$message({type: 'info', message: 'Stergere efectuata cu succes'});                                                                       
                        }).catch(() => {
                            this.$message({type: 'info', message: 'Stergere anulata'});                                                                 
                            });
            },

            async reactivate(id){
                this.$confirm(`Sunteti sigur ?`, 'Warning', {               
                    type: 'warning'
                    }).then(async() => {
                        await this.post("utilizatori/reactivate", { id: id } );
                        this.refresh_info(); 
                        this.$message({type: 'info', message: 'Reactivare efectuata cu succes'});                                                                       
                        }).catch(() => {
                            this.$message({type: 'info', message: 'Reactivare anulata'});                                                                 
                            });
            },

            show_dialog(id){
                this.$refs.dlg.show_me(id);
            },
            modifica_parola(id){
                this.$refs['changePassDlg'].show_me(id);
            }
        },
        mounted(){
            this.base_url = settings.BASE_URL;
            this.get_info();
        }
    };
</script>

<style lang="less" scoped>

    .top50{
        margin-top: 20px;
    }

    .filtre{
        .el-input-number
        {
            width: 100% !important;
        }
    }

</style>
